import React from 'react';

const Video = ({ videoSrcURL, videoTitle, aspectRatio, ...props }) => {
  const ratio = aspectRatio.toString();
  return (
    <div className="video">
      <iframe
        src={videoSrcURL}
        title={videoTitle}
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        frameBorder="0"
        webkitallowfullscreen="true"
        mozallowfullscreen="true"
        allowFullScreen
        style={{ width: '100%', aspectRatio: ratio }}
      />
    </div>
  );
};
export default Video;
