import React from 'react';
import styled from 'styled-components';
import { Avatar, Box, Stack, Text } from '@tymate/margaret';

const Content = styled(Stack).attrs({ direction: 'row' })`
  background-color: ${({ theme }) => theme.tertiary};
  color: #ffffff;
  border-radius: 2px;
  padding: ${({ theme }) => theme.spacing(1)};
  margin-top: ${({ theme }) => theme.spacing(2)};
  max-width: 25em;
  ${({ theme }) => theme.media.medium`
        max-width: 468px;
      `};
`;

const AuthorCard = data => {
  const authorName = Boolean(data?.data?.name)
    ? `Écrit par ${data?.data?.name}`
    : '';
  const avatarUrl = data?.data?.avatar?.url ?? '';
  const authorDescription = data?.data?.description ?? '';

  return (
    <Content>
      <Box>
        <Avatar size="medium" imageUrl={avatarUrl} />
      </Box>
      <Stack direction="column" paddingHorizontal={1} gutterSize={1}>
        <Box>
          <Text type="body" style={{ flexWrap: 'wrap' }}>
            {authorName}
          </Text>
        </Box>
        <Box>
          <Text type="bodySmall" style={{ flexWrap: 'wrap' }}>
            {authorDescription}
          </Text>
        </Box>
      </Stack>
    </Content>
  );
};

export default AuthorCard;
