import React from 'react';
import styled from 'styled-components';
import { Container, Stack, Text } from '@tymate/margaret';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Section, Tag } from '../ui/base';
import { Title } from 'ui';
import { StructuredText } from 'react-datocms';
import Video from './VideoPlayer';
import AuthorCard from './AuthorCard';

const ArticleDate = styled.div`
  font-size: 1rem;
  line-height: 1em;
  max-height: 1em;
  overflow: hidden;
  text-transform: uppercase;
  color: ${({ theme }) => theme.textSecondary};
  padding-bottom: ${({ theme }) => theme.spacing(2)};
`;

const Article = ({ data, variant }) => {
  const category = data?.category?.name ?? '';
  const articleTitle = data?.title ?? '';
  const picture = data?.picture;
  const date = data?.meta?.createdAt;
  const content = data?.content ?? '';
  const authorData = data?.author ?? {};

  return (
    <Section style={{ backgroundColor: '#ffffff' }} variant={variant}>
      <Container style={{ width: '45em' }} paddingHorizontal={1}>
        <Stack
          direction="column"
          alignX="flex-start"
          alignY="flex-start"
          gap={0.75}
        >
          <GatsbyImage
            image={getImage(picture)}
            alt={picture?.alt || picture?.title || ''}
            style={{
              overflow: 'hidden',
              maxHeight: '215px',
              borderRadius: '2px',
            }}
          />
          <Stack paddingVertical={0.75}>
            <Tag>{category}</Tag>
          </Stack>
          <Title>{articleTitle}</Title>
          <ArticleDate>{date}</ArticleDate>
          <StructuredText
            data={content}
            renderBlock={({ record }) => {
              switch (record.__typename) {
                case 'DatoCmsImage':
                  return (
                    <>
                      <img src={record.image.url} alt={record.image.alt} />
                      <Stack alignX="center" size="full">
                        <Text>{record.image.title}</Text>
                      </Stack>
                    </>
                  );
                case 'DatoCmsYoutube':
                  switch (record?.link?.provider) {
                    case 'youtube':
                      const videoSrcURL = `https://www.youtube.com/embed/${record?.link?.providerUid}`;
                      const ratio = record?.link?.width / record?.link?.height;
                      return (
                        <div style={{ width: '100%', aspectRatio: ratio }}>
                          <Video
                            videoSrcURL={videoSrcURL}
                            videoTitle={record.link.title}
                            aspectRatio={ratio}
                          />
                        </div>
                      );
                    default:
                      return null;
                  }

                default:
                  return null;
              }
            }}
          />
          <AuthorCard data={authorData}></AuthorCard>
        </Stack>
      </Container>
    </Section>
  );
};

export default Article;
