import React from 'react';
import { graphql } from 'gatsby';
import Article from 'components/Article';
import PartnersSection from 'components/PartnersSection';
import Layout from 'components/Layout';
import Seo from 'components/Seo';

const ArticlePage = ({ data }) => {
  const {
    datoCmsArticle,
    datoCmsGlobal,
    datoCmsMainMenu,
    datoCmsGettingInvolvedPage,
    datoCmsHomepage,
    allDatoCmsPartner,
  } = data;

  return (
    <Layout
      globalData={datoCmsGlobal}
      mainMenuData={datoCmsMainMenu}
      engagementKinds={datoCmsGettingInvolvedPage?.involvementKind}
    >
      <Seo
        title={datoCmsArticle?.metaTags?.title}
        description={datoCmsArticle?.metaTags?.description}
      />
      <Article data={datoCmsArticle} variant="topSection" />
      <PartnersSection
        data={datoCmsHomepage}
        partners={allDatoCmsPartner?.edges ?? []}
      />
    </Layout>
  );
};

export const query = graphql`
  query ($slug: String) {
    datoCmsArticle(slug: { eq: $slug }) {
      author {
        name
        description
        avatar {
          url
          alt
        }
      }
      metaTags {
        description
        title
      }
      meta {
        createdAt(formatString: "DD MMM YYYY", locale: "fr")
      }
      title
      description
      picture {
        url
        alt
        ... on DatoCmsFileField {
          gatsbyImageData
        }
      }
      category {
        name
      }

      content {
        blocks {
          __typename
          ... on DatoCmsImage {
            id: originalId
            image {
              gatsbyImageData
              url
              title
              isImage
            }
          }
          __typename
          ... on DatoCmsYoutube {
            id: originalId
            link {
              title
              url
              providerUid
              provider
              height
              width
            }
          }
        }
        value
        links
      }
    }

    datoCmsHomepage {
      partnersOvertitle
      partnersTitle
    }

    datoCmsGlobal {
      facebookLink
      instagramLink
      linkedinLink
      mailContact
      twitterLink
      footerText
      terms
    }

    allDatoCmsPartner {
      edges {
        node {
          id
          name
          logo {
            url
            alt
            ... on DatoCmsFileField {
              gatsbyImageData
            }
          }
        }
      }
    }

    datoCmsGettingInvolvedPage {
      involvementKind {
        title
        description
        information
        cta
        ctaLink
        icon {
          url
        }
      }
    }

    datoCmsMainMenu {
      blogLink
      galleryLink
      homepageLink
      involvmentLink
    }
  }
`;

export default ArticlePage;
